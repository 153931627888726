import BaseTheme from "../theme/BaseTheme";
import { Box } from "@radix-ui/themes";
import { Outlet } from "react-router-dom";

export default function MapLayout() {
  return (
    <BaseTheme>
      <Box
        style={{
          display: "flex",
          width: "100dvw",
          height: "100dvh",
        }}
      >
        <Outlet />
      </Box>
    </BaseTheme>
  );
}
